import React from "react"
import PageLayout from "../components/page-layout"
import {
  SectionLayout,
  ShortHeroSection,
  ContactuS,
} from "../components/sections"

import { HERO_SECTION_ID, CONTACT_US_ID } from "../constants"

export default function ContactUsPage() {
  return (
    <PageLayout>
      <ShortHeroSection
        sectionId={HERO_SECTION_ID}
        sectionTitle="Home / Contact Us"
      />
      <SectionLayout
        sectionId={CONTACT_US_ID}
        sectionTitle="Contact Us"
        hasFullWidth
      >
        <ContactuS />
      </SectionLayout>
    </PageLayout>
  )
}
